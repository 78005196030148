<template>
  <div h="full" flex="~ col">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>

</script>

<style></style>
